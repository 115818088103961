<template>
  <div v-if="publish_test_student.length > 0">
    <Card
      v-if="user.groups.includes(7)"
      class="dashboard-card mb-3"
      :variant_eighth="true"
    >
      <template v-slot:title>
        <b-icon class="card-header-icon" icon="calendar3"></b-icon>
        {{ $getVisibleNames("evaluations2.evaluation", true, "Evaluaciones") }}
        Terminadas</template
      >
      <template v-slot:content>
        <template v-if="institution && institution.kl_assessment">
          <template
            v-if="user.groups.includes(7) && publish_test_student.length > 0"
          >
            <b-table-simple
              bordered
              borderless
              small
              hover
              sticky-header="400px"
              class="border-table"
            >
              <b-thead>
                <b-tr>
                  <b-th class="primary-cell-next-test" style="width: 11%">{{
                    $getVisibleNames(
                      "mesh.egressprofilematter",
                      false,
                      "Asignatura"
                    )
                  }}</b-th>
                  <b-th class="primary-cell-next-test" style="width: 10%">{{
                    $getVisibleNames(
                      "evaluations2.evaluation",
                      false,
                      "Evaluación"
                    )
                  }}</b-th>

                  <b-th class="primary-cell-next-test" style="width: 10%">{{
                    $getVisibleNames("evaluations2.test", false, "Instrumento")
                  }}</b-th>
                  <b-th class="primary-cell-next-test" style="width: 7%">
                    Docentes
                  </b-th>
                  <!-- <b-th class="primary-cell-next-test" style="width: 7%"
                  >Fecha Inicio</b-th
                >
                <b-th class="primary-cell-next-test" style="width: 7%"
                  >Fecha Término</b-th
                > -->
                </b-tr>
              </b-thead>

              <b-tbody>
                <template v-for="test in publish_test_student">
                  <b-tr :key="test.id">
                    <!-- Nombre de la Evaluacion (Evaluacion) -->
                    <b-td class="secondary-cell-next-test text-left">
                      <div class="mr-0">
                        {{ test.matter_name }}
                      </div>
                    </b-td>
                    <!-- Nombre de la Evaluacion (Evaluacion) -->
                    <b-td class="secondary-cell-next-test text-left">
                      <div class="mr-0">
                        {{ test.evaluation_title }}
                      </div>
                    </b-td>
                    <!-- Nombre de la Evaluacion (Test) -->
                    <b-td class="secondary-cell-next-test text-left">
                      <div
                        class="div-href-test mr-0"
                        @click="
                          redirectToTestStudent(test.evaluation_id, test.id)
                        "
                        v-b-tooltip.v-secondary.bottom.noninteractive="
                          `Ir a la ${$getVisibleNames(
                            'evaluations2.evaluation',
                            true,
                            'Evaluación'
                          )}`
                        "
                      >
                        {{ test.title }}
                      </div>
                    </b-td>
                    <b-td class="secondary-cell-next-test text-left">
                      <div>
                        {{ test.section_professors.join(", ") }}
                      </div>
                    </b-td>
                    <!-- Fecha de Inicio -->
                    <!-- <b-td class="secondary-cell-next-test">
                    <template v-if="test.start_time">
                      {{ test.start_time | FormatToDateTime }}
                    </template>
                    <template v-else> N/A </template>
                  </b-td> -->
                    <!-- Fecha de Termino -->
                    <!-- <b-td class="secondary-cell-next-test">
                    <template v-if="test.end_time">
                      {{ test.end_time | FormatToDateTime }}
                    </template>
                    <template v-else> N/A </template>
                  </b-td> -->
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </template>

          <template
            v-if="user.groups.includes(7) && publish_test_student.length == 0"
          >
            <div class="text-left">
              No hay
              {{
                $getVisibleNames(
                  "evaluations2.evaluation",
                  true,
                  "Evaluaciones"
                )
              }}
            </div>
          </template>
        </template>
        <template v-else>
          <div class="text-left">
            <span>Este módulo no está habilitado para su institución</span>
          </div>
        </template>
      </template>
    </Card>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "PublishTestStudent",
  components: {
    Card: () => import("@/components/users/Card"),
  },
  data() {
    return {
      publish_test_student: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
    }),
  },
  methods: {
    redirectToTestStudent(evaluation_id, instrument_id = null) {
      this.$router.push({
        name: "EvaluateeView",
        params: {
          evaluation_id: evaluation_id,
          instrument_id: instrument_id,
        },
      });
    },
    getPublishtTestStudent() {
      if (
        this.user &&
        this.user.groups.includes(7) &&
        this.publish_test_student.length == 0 &&
        this.institution &&
        this.institution.kl_assessment
      ) {
        this.$restful
          .Get(
            `evaluations2/dashboard_student_next_tests/?want_finished_tests=${true}`
          )
          .then((response) => {
            this.publish_test_student = response;
          });
      }
    },
  },
  mounted() {
    this.getPublishtTestStudent();
  },
  watch: {
    user() {
      this.getPublishtTestStudent();
    },
    institution() {
      this.getPublishtTestStudent();
    },
  },
  created() {},
};
</script>

<style scoped>
.dashboard-card {
  display: block;
  border-radius: 7px;
  margin-top: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 1px 8px -1px #9d9d9d;
  /* box-shadow: 0px 1px 5px 0px #9d9d9d; */
  transition: all 0.5s ease-out;
}
.card-header-icon {
  margin-top: 2px;
  margin-right: 0.1em;
}
.border-table {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.primary-cell-next-test {
  text-align: left;
  vertical-align: middle;
  background: #f2d5b4 !important;
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.secondary-cell-next-test {
  text-align: center;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.div-href-test {
  color: #007bff;
}
.div-href-test:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
@media print {
  .secondary-cell-next-test {
    background-color: transparent !important;
  }
}
</style>